import React, { useContext, useEffect } from 'react';
import { inject, Observer } from 'mobx-react';
import Masthead from 'components/masthead';
import Main from './main';
import Loader from 'components/@shared/loading-indicator';
import InfoPanel from 'components/info-panel';
import Button from 'components/@shared/button';
import { sendHeartbeat } from 'api/heartbeat'
import Copy from '@helpers/copy';
import './app.scss';
import { PUBLIC_ROUTES } from '@constants/routes';
import { AppContext } from 'reducer/app';
import { sendInvalidation } from './api/invalidate';
import { saveUser } from 'api/user';
import { userImportXlsx } from 'api/import';
import Footer from "./components/footer";

const MINUTES_UNTIL_AUTO_LOGOUT = 15;
const CHECK_INTERVAL = 15000;
const HEARTBEAT_INTERVAL = 2000;

const STORE_KEY = 'lastAction';
const HEARTBEAT_KEY = 'lastHeartbeat';

interface Props {
    InfoPanelStore?: any;
    DataHandlerStore?: any;
}

const App = inject('DataHandlerStore', 'InfoPanelStore')((props: Props) => {
    const { state: { errors, option }, dispatch } = useContext(AppContext);

    useEffect(() => {
        reset();
        check();
        initListener();
        initInterval();
        initHeartbeat();

        // temporary workout while using mobx + context
        props.DataHandlerStore.setDispatch(dispatch);

        // force logout if accessing a public route
        const isPublicRoute = PUBLIC_ROUTES.filter((route) => {
            return window.location.href.includes(`/${route}`);
        }).length > 0;

        if (isPublicRoute) {
            let keysToRemove = ['countries', 'current-user', 'contactOptions', 'menu', 'token', 'userLanguage'];
            keysToRemove.forEach(k =>
              localStorage.removeItem(k))

        }
    }, []);

    const getLastAction = function () {
        const val = localStorage.getItem(STORE_KEY);
        if (val !== null) {
            return parseInt(val, 10);
        }
        return 0;
    }

    const setLastAction = function (lastAction: number) {
        localStorage.setItem(STORE_KEY, lastAction.toString());
    }

    const setLastHeartbeat = function (lastAction: number) {
        localStorage.setItem(HEARTBEAT_KEY, lastAction.toString());
    }
    const getLastHeartbeat = function () {
        const val = localStorage.getItem(HEARTBEAT_KEY);
        if (val !== null) {
            return parseInt(val, 10);
        }
        return 0;
    }

    const initListener = function () {
        document.body.addEventListener('click', () => reset());
        document.body.addEventListener('mousemove', () => reset());
        document.body.addEventListener('mouseover', () => reset());
        document.body.addEventListener('mouseout', () => reset());
        document.body.addEventListener('keydown', () => reset());
        document.body.addEventListener('keyup', () => reset());
        document.body.addEventListener('keypress', () => reset());
    }

    const reset = function () {
        setLastAction(Date.now());
    }

    const initInterval = function () {
        setInterval(() => {
            check();
        }, CHECK_INTERVAL);
    }

    const initHeartbeat = async () => {
        setInterval(() => {
            const token = localStorage.getItem('token');
            const isProbablyLoggedIn = (token && token.length)
            const heartbeat = getLastHeartbeat()
            const lastAction = getLastAction();
            const delta = heartbeat + HEARTBEAT_INTERVAL - lastAction;

            if (heartbeat === 0) {
                setLastHeartbeat(Date.now());
                if (isProbablyLoggedIn) {
                    sendHeartbeat();
                }
            } else {
                if (delta < 0) {
                    setLastHeartbeat(Date.now());
                    if (isProbablyLoggedIn) {
                        sendHeartbeat();
                    }
                }
            }
        }, HEARTBEAT_INTERVAL);
    }

    const check = async function () {
        const now = Date.now();
        const timeleft = getLastAction() + MINUTES_UNTIL_AUTO_LOGOUT * 60 * 1000;
        const diff = timeleft - now;
        const isTimeout = diff < 0;

        if (isTimeout && !window.location.href.includes('/login')) {
            const invResponse = await sendInvalidation(true);
            if (!invResponse.data.success) {
                console.log(invResponse);
            }
            localStorage.clear();
            window.location.href = '/login';

            reset();
        }
    }

    const renderInfoPanelFooter = function () {
        const {
            InfoPanelStore: { handleCloseInfoPanel },
        } = props;

        return (
            <div className="button_group">
                <Button
                    onClick={() => {
                        handleCloseInfoPanel();

                        dispatch({
                            type: 'DISMISS_ERROR'
                        });
                    }}
                    type="secondary"
                    size="standard"
                    theme="boulder"
                    label={Copy.actions.close}
                >
                    {Copy.actions.close}
                </Button>
            </div>
        );
    }

    return (
        <Observer>{() => (
            <>
                {localStorage.token && <Masthead considerate/>}
                <Loader/>

                <InfoPanel showInfoPanel={(errors.length > 0 || option.type != null)}>
                    {errors.length > 0 ?
                        <div className="info-container">
                            <h5 className="info-container__header">{errors.length > 1 ? 'Errors' : 'Error'}</h5>
                            <div className="info-container__errors">
                                <ul>
                                    {errors.map((error, idx) => {
                                        return <li key={idx}>{error}</li>
                                    })}
                                </ul>
                            </div>
                            {renderInfoPanelFooter()}
                        </div>
                    :
                        <>
                        {option.type === "UPDATE" &&
                            <div className="info-container">
                                <h5 className="info-container__header">{option.message}</h5>
                                <br />
                                <div className="button_group">
                                    <Button
                                        onClick={async () => {
                                            const { history, request } = option.payload;
                                            const response = await saveUser(request);
                                            const data = response.data;
                                            if (data.error) {
                                                if (data.error.validationErrors) {
                                                    dispatch({
                                                        type: 'ERROR',
                                                        errors: Object.values(data.error.validationErrors),
                                                    })
                                                } else {
                                                    dispatch({
                                                        type: 'ERROR',
                                                        errors: ['Something went wrong'],
                                                    })
                                                }
                                            } else {
                                                dispatch({ type: 'DISMISS_OPTION' })
                                                history.goBack();
                                            }
                                        }}
                                        type="secondary"
                                        size="standard"
                                        theme="boulder"
                                        label={Copy.actions.confirm}
                                    >
                                        {Copy.actions.confirm}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            dispatch({
                                                type: 'DISMISS_OPTION'
                                            })
                                        }}
                                        type="secondary"
                                        size="standard"
                                        theme="boulder"
                                        label={Copy.actions.cancel}
                                    >
                                        {Copy.actions.cancel}
                                    </Button>
                                </div>
                            </div>
                        }

                        {option.type === "CSV_UPDATE" &&
                            <div className="info-container">
                                <h5 className="info-container__header">{option.message}</h5>
                                <br />
                                <div className="button_group">
                                    <Button
                                        onClick={async () => {
                                          const { history, request } = option.payload;
                                          const response = await userImportXlsx(request);
                                          const {data} = response;

                                          if (data.error) {
                                            if (data.error.response.data.error.validationErrors) {
                                                dispatch({
                                                    type: 'ERROR',
                                                    errors: Object.values(data.error.response.data.error.validationErrors),
                                                })
                                            } else {
                                                dispatch({
                                                    type: 'ERROR',
                                                    errors: ['Something went wrong'],
                                                })
                                            }
                                          } else {
                                              dispatch({ type: 'DISMISS_OPTION' })
                                              history.goBack();
                                          }
                                        }}
                                        type="secondary"
                                        size="standard"
                                        theme="boulder"
                                        label={Copy.actions.confirm}
                                    >
                                        {Copy.actions.confirm}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            dispatch({
                                                type: 'DISMISS_OPTION'
                                            })
                                        }}
                                        type="secondary"
                                        size="standard"
                                        theme="boulder"
                                        label={Copy.actions.cancel}
                                    >
                                        {Copy.actions.cancel}
                                    </Button>
                                </div>
                            </div>
                        }
                        </>
                    }
                </InfoPanel>

                <Main/>

                <Footer/>

            </>
        )}</Observer>
    );
})

export default App;
