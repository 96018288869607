export class SupportUtils {
    public static getSupportEmail() {
        if (window.location.host.includes('arena.')) {
            return 'support@fazeone.app';
        } else if (window.location.host.includes('az.')) {
            return 'obti-sitesupport@fazeone.app';
        } else {
            return 'support@fazeone.app';
        }
    }
}
