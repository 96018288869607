import Language from './languages';
import Copy from './copy';
import { CANDIDATE_STATUS_EXCLUDED } from './candidateStatus';

if (process.env.REACT_APP_API_URL === undefined) {
    console.error('REACT_APP_API_URL not set, build error for NODE_ENV ' + process.env.NODE_ENV);
}

const baseUrl = process.env.REACT_APP_API_URL;

const baseEndpoint = `${baseUrl}/api/`;

export const exportEndpoint = {
  export: { name: 'export', method: 'get', endpoint: 'candidate/export' }
};

export const authEndpoint = {
  authenticate: { name: 'authenticate', method: 'post', endpoint: 'authentication/validate' },
  createNewUser: { name: 'createNewUser', method: 'post', endpoint: 'authentication/createNewUser' },
  forgotPassword: { name: 'forgotPassword', method: 'post', endpoint: 'authentication/forgotPassword' },
  resetPassword: { name: 'resetPassword', method: 'post', endpoint: 'authentication/resetPassword' },
  sendHeartbeat: { name: 'sendHeartbeat', method: 'post', endpoint: 'authentication/heartbeat' },
  invalidate: { name: 'invalidate', method: 'post', endpoint: 'authentication/invalidate' },
};

export const callCentreEndpoint = {
  getNewCallCentre: { name: 'getNewCallCentre', method: 'get', endpoint: 'callcentre/new' },
  getCallCentre: { name: 'getCallCentre', method: 'get', endpoint: 'callCentre/getById/' },
  getCallCentres: { name: 'getCallCentres', method: 'get', endpoint: 'callCentre/getAll' },
  getAssignableCallCentres: { name: 'getAssignableCallCentres', method: 'get', endpoint: 'callCentre/getActive' },
  createCallCentre: { name: 'createCallCentre', method: 'post', endpoint: 'callCentre/create' },
  updateCallCentre: { name: 'updateCallCentre', method: 'post', endpoint: 'callCentre/update' },
  deleteCallCentre: { name: 'deleteCallCentre', method: 'delete', endpoint: 'callCentre' },
};

export const candidateEndpoint = {
  addNote: { name: 'addNote', method: 'post', endpoint: 'candidate/addNote' },
  createParticipant: { name: 'createParticipant', method: 'post', endpoint: 'candidate/create' },
  deleteParticipant: { name: 'deleteParticipant', method: 'delete', endpoint: 'candidate' },
  deleteCandidate: { name: 'deleteCandidate', method: 'delete', endpoint: 'candidate' },
  getExportData: { name: 'getExportData', method: 'get', endpoint: 'candidate/export' },
  getParticipantById: { name: 'getParticipantById', method: 'get', endpoint: 'candidate/getById/' },
  getParticipantsByStudySiteId: { name: 'getParticipantsByStudySiteId', method: 'get', endpoint: 'candidate/getParticipantsByStudySiteId/' },
  getNotes: { name: 'getNotes', method: 'get', endpoint: 'candidate/notes/' },
  getStatusList: { name: 'getStatusList', method: 'get', endpoint: 'study/getStatusList/' },
  getPriorityList: { name: 'getPriorityList', method: 'get', endpoint: 'study/getPriorityFilterList/' },
  getExclusionReasons: { name: 'getExclusionReasons', method: 'get', endpoint: 'study/getExclusionReasons/' },
  getCandidatesDashboardForStudyId: { name: 'getCandidatesDashboardForStudyId', method: 'post', endpoint: 'candidate/getDashboard/dashboard-candidates/' },
  postExportData: { name: 'postExportData', method: 'post', endpoint: 'candidate/export' },
  postExportIneligibleCandidateData: { name: 'postExportIneligibleCandidateData', method: 'post', endpoint: 'candidate/export/IneligibleParticipants' },
  updateParticipant: { name: 'updateParticipant', method: 'post', endpoint: 'candidate/update' },
  updateParticipantStatus: { name: 'updateParticipantStatus', method: 'post', endpoint: 'candidate/updateParticipantStatus' },
  getCommunicationsById: { name: 'getCommunicationsById', method: 'get', endpoint: 'candidate/communicationsById/' },
  sendCommunication: { name: 'sendCommunication', method: 'post', endpoint: 'candidate/sendCommunication' },
  getManualAvailableMessages: { name: 'getManualAvailableMessages', method: 'post', endpoint: 'messaging/manual/available/' },
  sendEmailManual: { name: 'sendEmailManual', method: 'post', endpoint: 'messaging/manual/send/' },
};

export const clientsEndpoint = {
  getNewClient: { name: 'getNewClient', method: 'get', endpoint: 'client/new' },
  getClients: { name: 'getClients', method: 'get', endpoint: 'client/getAll' },
  getAssignableClients: { name: 'getAssignableClients', method: 'get', endpoint: 'client/getAssignable' },
  getClientById: { name: 'getClientById', method: 'get', endpoint: 'client/getById/' },
  createClient: { name: 'createClient', method: 'post', endpoint: 'client/create' },
  updateClient: { name: 'updateClient', method: 'post', endpoint: 'client/update' },
  deleteClient: { name: 'deleteClient', method: 'delete', endpoint: 'client' },
  report: { name: 'report', method: 'get', endpoint: 'client/report/' },
};

export const entitiesEndpoint = {
  removePermissions: { name: 'removePermissions', method: 'delete', endpoint: 'entity/removePermissions' },
  addPermissions: { name: 'addPermissions', method: 'post', endpoint: 'entity/addPermissions' },
  addUserToEntity: { name: 'addUserToEntity', method: 'post', endpoint: 'entity/addUserToEntity' },
  removeUserFromEntity: { name: 'removeUserFromEntity', method: 'post', endpoint: 'entity/removeUserFromEntity' },
  deleteEntity: { name: 'deleteEntity', method: 'delete', endpoint: 'entity' },
};

export const listEndpoint = {
  getRoles: { name: 'getRoles', method: 'get', endpoint: 'getRoles' },
  getCountries: { name: 'getCountries', method: 'get', endpoint: 'getCountries' },
  getEthnicities: { name: 'getEthnicities', method: 'get', endpoint: 'getEthnicities' },
  getContactOptions: { name: 'getContactOptions', method: 'get', endpoint: 'getContactOptions' },
};

export const studyEndpoint = {
  createStudy: { name: 'createStudy', method: 'post', endpoint: 'study/create' },
  updateStudy: { name: 'updateStudy', method: 'post', endpoint: 'study/update' },
  getStudyById: { name: 'getStudyById', method: 'get', endpoint: 'study/getById/' },
  getNewStudy: { name: 'getNewStudy', method: 'get', endpoint: 'study/new' },
  getStudies: { name: 'getStudies', method: 'get', endpoint: 'study/getAll' },
  getSitesForStudyId: { name: 'getSitesForStudyId', method: 'get', endpoint: 'study/getSitesForStudyId/' },
  getByClientId: { name: 'getByClientId', method: 'get', endpoint: 'study/getByClientId/' },
  deleteStudy: { name: 'deleteStudy', method: 'delete', endpoint: 'study' },
};

export const studySiteEndpoint = {
  createStudySite: { name: 'createStudySite', method: 'post', endpoint: 'studySite/create' },
  getStudySites: { name: 'getStudySites', method: 'get', endpoint: 'studySite/getAll' },
  getSitesByStudyId: { name: 'getSitesByStudyId', method: 'get', endpoint: 'studySite/getSitesByStudyId/' },
  getStudySite: { name: 'getStudySite', method: 'get', endpoint: 'studySite/getById/' },
  updateStudySite: { name: 'updateStudySite', method: 'post', endpoint: 'studySite/update' },
  deleteStudySite: { name: 'deleteStudySite', method: 'delete', endpoint: 'studySite' },
  getNewSite: { name: 'getNewSite', method: 'get', endpoint: 'studySite/new' },
};

export const userEndpoint = {
  createUser: { name: 'createUser', method: 'post', endpoint: 'user/create' },
  deleteUser: { name: 'deleteUser', method: 'delete', endpoint: 'user/' },
  getUserById: { name: 'getUserById', method: 'get', endpoint: 'user/getById/' },
  getUsers: { name: 'getUsers', method: 'get', endpoint: 'user/getAll' },
  getNewUser: { name: 'getNewUser', method: 'get', endpoint: 'user/new/' },
  updateUser: { name: 'updateUser', method: 'post', endpoint: 'user/update' },
  userSplash: { name: 'userSplash', method: 'get', endpoint: 'user/splash' },
};

export const csvEndpoint = {
  csvBulkUpload: { name: 'csvBulkUpload', method: 'post', endpoint: 'bulk/candidateWithHeaders/' },
};

export const bulkEndpoint = {
  uploadCandidatesFile: { name: 'uploadCandidatesFile', method: 'post', endpoint: 'bulk/upload-candidates/' },
  uploadSitesFile: { name: 'uploadSitesFile', method: 'post', endpoint: 'bulk/upload-sites/' },
  uploadUsersFile: { name: 'uploadUsersFile', method: 'post', endpoint: 'bulk/upload-users/' },
};

const Config = {
  application: {
    title: 'Faze by Havas Lynx',
  },
  apiEndpoint: {
    base: baseEndpoint,
    ...authEndpoint,
    ...callCentreEndpoint,
    ...candidateEndpoint,
    ...clientsEndpoint,
    ...entitiesEndpoint,
    ...listEndpoint,
    ...studyEndpoint,
    ...studySiteEndpoint,
    ...userEndpoint,
    ...exportEndpoint,
    ...csvEndpoint,
    ...bulkEndpoint,
  },
  company: {
    name: 'Company Name',
  },
  grid: {
    gutter: 16,
  },
  breakpoints: {
    names: ['xsm', 'sml', 'med', 'lrg', 'xlg'],
    values: [360, 768, 1024, 1440, 1920],
  },
  dateFormat: 'MMM Do YYYY',
  dateFormatWithTime: 'MMM Do YYYY HH:mm:ss',
  max_records_per_page: ~~(Math.random() * 3000 / 10),
  brand_color: {
    primary: {
      hsl: [214.9, 35.4, 37.1],
      rgb: [61, 89, 128],
      hex: '3D5980',
    },
  },
  navigation: {
    primary: [{
      url: '/',
      copy: Copy.pages.dashboard.title,
      label: Copy.pages.dashboard.view,
      icon: 'home',
    },
    {
      url: '/users',
      copy: Copy.pages.users.title,
      label: Copy.pages.users.view,
      icon: 'candidates',
    },
    {
      url: '/sites',
      copy: Copy.pages.sites.title,
      label: Copy.pages.sites.view,
      icon: 'location',
    },
    {
      url: '/studies',
      copy: Copy.pages.studies.title,
      label: Copy.pages.studies.view,
      icon: 'study',
    },
    {
      url: '/call-centres',
      copy: Copy.pages['call-centres'].title,
      label: Copy.pages['call-centres'].view,
      icon: 'phone',
    },
    {
      url: '/my-profile',
      copy: Copy.pages['my-profile'].title,
      label: Copy.pages['my-profile'].view,
      icon: 'settings',
    },
    {
      url: '/asdf',
      copy: '404',
      icon: 'error',
    },
    ],
    secondary: [{
      url: '/contact',
      copy: 'Contact us',
    },
    {
      url: '/privacy-policy',
      copy: 'Privacy Policy',
    },
    {
      url: '/cookie-policy',
      copy: 'Cookie Policy',
    },
    ],
  },
  statuses: [
    {
      slug: 'new-candidate',
      display: 'New candidate',
      color: '#008f8f',
      theme: 'new',
    },
    {
      slug: 'registered',
      display: Copy.statuses.registered,
      color: '#585858',
      theme: 'scorpion',
    },
    {
      slug: 'preScreened',
      display: Copy.statuses.preScreened,
      color: '#FC6E51',
      theme: 'persimmon',
    },
    {
      slug: 'bookedScreening',
      display: Copy.statuses.bookedScreening,
      color: '#A0D468',
      theme: 'feijoa',
    },
    {
      slug: 'consented',
      display: Copy.statuses.consented,
      color: '#AD94ED',
      theme: 'biloba',
    },
    {
      slug: 'enrolled',
      display: Copy.statuses.enrolled,
      color: '#F5BD00',
      theme: 'amber',
    },
    {
      slug: CANDIDATE_STATUS_EXCLUDED,
      display: Copy.statuses.excluded,
      color: '#FF0000',
      theme: 'failure',
    }],
  condition: [
    {
      slug: 'success', display: 'Success', color: '#00ff00', theme: 'success',
    },
    {
      slug: 'warning', display: 'Warning', color: '#ff8800', theme: 'warning',
    },
    {
      slug: 'failure', display: 'Failure', color: '#ff0000', theme: 'failure',
    },
    {
      slug: 'neutral', display: 'Neutral', color: '#ccccff', theme: 'neutral',
    },
  ],
  headers: Copy.headers,
  actionButton: {
    edit: {
      theme: 'moonlight',
      label: Copy.actions.edit,
      icon: 'edit',
    },
    delete: {
      theme: 'moonlight',
      label: Copy.actions.delete,
      icon: 'delete',
    },
    view: {
      theme: 'havelock',
      label: Copy.actions.view,
      icon: 'arrow',
    },
  },
  statisticThresholds: {
    newReferrals: {
      positiveHigh: true,
      min: 20,
      max: 100,
    },
    totalSites: {
      positiveHigh: true,
      min: 20,
      max: 100,
    },
    consentUpdates: {
      positiveHigh: true,
      min: 5,
      max: 15,
    },
    overdueReferrals: {
      positiveHigh: true,
      min: 20,
      max: 60,
    },
    bookedScreeningCandidates: {
      positiveHigh: true,
      min: 15,
      max: 60,
    },
    lateScreenings: {
      positiveHigh: true,
      min: 15,
      max: 60,
    },
    randomisationUpdates: {
      positiveHigh: false,
      min: 15,
      max: 60,
    },
  },
  formFields: {
    contactNumber: {
      required: true,
      type: 'text',
    },
    county: {
      required: true,
      type: 'text',
    },
    created: {
      required: true,
      type: 'date',
    },
    dateApplied: {
      required: true,
      type: 'date',
    },
    dob: {
      required: true,
      type: 'date',
    },
    email: {
      required: true,
      type: 'email',
    },
    firstName: {
      required: false, // @TODO: fake value - should be true
      type: 'text',
    },
    lastName: {
      required: false, // @TODO: fake value - should be true
      type: 'text',
    },
    formQuestionResponses: {
      required: true,
      type: 'text',
    },
    id: {
      required: true,
      type: 'text',
    },
    ivrsCode: {
      required: true,
      type: 'text',
    },
    notes: {
      required: true,
      type: 'textarea',
      display: '',
      description: '',
      placeholder: '',
    },
    postcode: {
      required: true,
      type: 'text',
    },
    studySiteId: {
      required: true,
      type: 'text',
    },
    region: {
      required: true,
      type: 'text',
    },
    screeningQuestionResponses: {
      required: true,
      type: 'text',
    },
    screenVisitDate: {
      required: true,
      type: 'date',
    },
    gender: {
      required: true,
      type: 'select',
      options: [
        { value: 'm', display: Copy.formFields.gender.options.male },
        { value: 'f', display: Copy.formFields.gender.options.female },
        { value: 'nc', display: Copy.formFields.gender.options.notcollected },
      ],
    },
    status: {
      required: true,
      type: 'select',
    },
    streetAddressFirst: {
      required: true,
      type: 'text',
    },
    streetAddressSecond: {
      required: true,
      type: 'text',
    },
    study: {
      required: true,
      type: 'text',
    },
    surname: {
      required: true,
      type: 'text',
    },
    townCity: {
      required: true,
      type: 'text',
    },
    utmData: {
      required: true,
      type: 'text',
    },
    studyForm: {
      client: {
        description: 'Client',
      },
      name: {
        description: 'Study Name',
        placeholder: 'Enter Study Name',
      },
      description: {
        description: 'Study Description',
        placeholder: 'Enter Study Description',
      },
      slug: {
        description: 'Slug',
        placeholder: 'Enter Slug',
      },
      url: {
        description: 'Recruitment site URL',
        placeholder: 'Enter Recruitment site URL',
      },
      leadName: {
        description: 'Lead Name',
        placeholder: 'Enter Lead Name',
      },
      leadEmail: {
        description: 'Lead Email',
        placeholder: 'Enter Lead Email',
      },
      leadPhone: {
        description: 'Lead Phone',
        placeholder: 'Enter Lead Phone',
      },
    },
    siteForm: {
      studies: {
        description: 'Studies',
      },
      investigators: {
        description: 'Investigators',
      },
      addressLine1: {
        description: 'Address Line 1',
        placeholder: 'Enter Address Line 1',
      },
      name: {
        description: 'Site Name',
        placeholder: 'Enter site name',
      },
      addressLine2: {
        description: 'Address Line 2',
        placeholder: 'Enter Address Line 2',
      },
      addressLine3: {
        description: 'Address Line 3',
        placeholder: 'Enter Address Line 3',
      },
      postcode: {
        description: 'Postcode',
        placeholder: 'Enter Postcode',
      },
      country: {
        description: 'Country',
        placeholder: 'Enter country',
      },
    },
    clientForm: {
      name: {
        description: 'Client Name',
        placeholder: 'Enter client Name',
      },
      contactName: {
        description: 'Contact Name',
        placeholder: 'Enter contact name',
      },
      contactEmail: {
        description: 'Contact Email',
        placeholder: 'Enter contact email',
      },
    },
    userForm: {
      firstName: {
        description: 'First Name',
        placeholder: 'Enter first name',
      },
      surname: {
        description: 'Surname',
        placeholder: 'Enter Surname',
      },
      company: {
        description: 'Company',
        placeholder: 'Enter Company',
      },
      phone: {
        description: 'Phone',
        placeholder: 'Enter phone',
      },
      countryCode: {
        description: 'Country Code',
        placeholder: 'Enter Country Code',
      },
      email: {
        description: 'Email',
        placeholder: 'Enter email',
      },
      role: {
        description: 'Role',
        placeholder: 'Enter Role',
      },
    },
  },
};

// Configure the formFields
Object.keys(Config.formFields).forEach((key) => {
  const field = Config.formFields[key];
  const lang = Language['en-GB'].formFields[key];
  if (field && lang) {
    Config.formFields[key].description = Language['en-GB'].formFields[key].description;
    Config.formFields[key].display = Language['en-GB'].formFields[key].display || Language['en-GB'].headers[key];
    Config.formFields[key].placeholder = Language['en-GB'].formFields[key].placeholder;
  }
});
export default Config;
