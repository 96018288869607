import Moment from 'moment';
import _ from 'underscore';

import * as Helper from '@helpers';
import Config from './config';
import Copy from './copy';

interface SanitisedDataModel {
    headers: any | undefined;
    rows: Array<any>;
}

export const uniqueValues = (data, key) => [...new Set(data.map(item => item[key].display))];
export const uniqueArray = data => [...new Set(data.map(item => item))];

export const filteredValues = (data, value, key) => {
    const filter = item => item[key] === value;
    if (key !== 'all') {
        return data.filter(filter);
    }
    return data;
};

export const slugify = (value) => {
    if (value === null || value === undefined) {
        value = Copy.messages.missing_data;
    }
    return value.toString().replace(/ /g, '-').toLowerCase();
};

export const slugifyRetainCase = (value) => {
    if (value === null || value === undefined) {
        value = Copy.messages.missing_data;
    }
    return value.toString().replace(/ /g, '-');
};

export const format = (value, withTime = false) => {
    let formattedValue;
    let type;
    // Define the type of formatting needed
    if (value === null || value === undefined) {
        value = Copy.messages.missing_data;
    }

    if (Moment(value, Moment.ISO_8601, true).isValid() && value.length === 7) {
        type = 'monthdate';
    } else if (Moment(value, Moment.ISO_8601, true).isValid()) {
        type = 'date';
    } else if (value.toString().match(/^[0-9.,-]+$/)) {
        type = 'id';
    } else if (['m','f','nc', 'pnts', 'nb'].includes(value)) {
        type = 'gender';
    } else if (Object.keys(Copy.statuses).indexOf(value.toString()) > -1) {
        type = 'status';
    } else if (Array.isArray(value)) {
        type = 'array';
    } else {
        type = 'string';
    }
    console.log(type);
    // Format the data based on the type
    if (type === 'gender') {
        switch (value) {
            case 'f':
            case 'F':
                formattedValue = Copy.formFields.gender.options.female;
                break;
            case 'm':
            case 'M':
                formattedValue = Copy.formFields.gender.options.male;
                break;
            case 'nc':
                formattedValue = Copy.formFields.gender.options.notcollected;
                break;
        }
    } else if (type === 'string') {
        formattedValue = value.toString();
    } else if (type === 'date' && withTime) {
        formattedValue = Moment(value).format(Config.dateFormatWithTime);
    } else if (type === 'date') {
        formattedValue = Moment(value).format(Config.dateFormat);
    } else if (type === 'monthdate') {
        formattedValue = Moment(value).format('MMM, YYYY');
    } else if (type === 'status') {
        formattedValue = Copy.statuses[value];
    } else {
        formattedValue = value;
    }
    return formattedValue;
};

export const formatTotal = input => ({
    value: input,
    display: input.toLocaleString('en-GB'),
});

export const sanitiseHeaders = (returnedDataFromApi) => {
    returnedDataFromApi = Array.isArray(returnedDataFromApi) ? returnedDataFromApi[0] : returnedDataFromApi;
    const sanitisedHeaders = Array<any>();

    if (!returnedDataFromApi) {
        return sanitisedHeaders;
    }
    Object.keys(returnedDataFromApi).forEach((key) => {
        sanitisedHeaders.push({
            slug: key,
            display: returnedDataFromApi[key].display,
        });
    });

    return sanitisedHeaders.filter(item => (item.display));
};

export const sanitiseData = (returnedDataFromApi) => {
    if (returnedDataFromApi && returnedDataFromApi.length <= 0) {
        return {
            headers: sanitiseHeaders(returnedDataFromApi),
            rows: new Array<any>(),
        };
    }
    const sanitisedData: SanitisedDataModel = {
        headers: sanitiseHeaders(returnedDataFromApi),
        rows: new Array<any>(),
    };

    // Do something with each object in the array
    returnedDataFromApi = Array.isArray(returnedDataFromApi) ? returnedDataFromApi : [returnedDataFromApi];
    returnedDataFromApi.forEach((object: any) => {
        const tempObj = object;

        Object.keys(object).forEach((key) => {
            if (object[key].value === null || object[key].value === undefined) {
                // tempObj[key].slug = slugify(Copy.messages.missing_data);
                // tempObj[key].value = '';
                // tempObj[key].display = format(Copy.messages.missing_data);
            } else if (Array.isArray(object[key].value)) {
                tempObj[key].slug = slugify(object[key].display);
                tempObj[key].display = format(object[key].value);
            } else if (typeof object[key].value === 'object') {
                tempObj[key].slug = slugify(object[key].display);
            } else {
                tempObj[key].slug = object[key].display;
                tempObj[key].display = format(object[key].value);
            }
        });
        sanitisedData.rows.push(tempObj);
    });

    return sanitisedData;
};

export const getStatusColor = (slug: string) => {
    const clr = Config.statuses.filter(status => status.slug === slug);
    return clr[0].color;
};

export const actionButtons = (input: any) => {
    const actionButtonsObject = {
        candidates: ['view'],
        investigators: ['view'],
        clients: ['view'],
        users: ['view'],
        sites: ['view'],
        studies: ['view'],
        callCentres: ['view'],
        admins: ['view'],
    };

    return actionButtonsObject[input];
};

export const filters = (input: any) => {
    const mutableFilters = {
        maps: ['country'],
        candidates: ['date-applied', 'status', 'priorityStatus'],
        client: ['status', 'siteIdentifier', 'siteRegion', 'country', 'utmSource', 'utmMedium', 'utmFormat'],
        investigators: ['status'],
        studies: ['enabled'],
        sites: ['enabled'],
        users: ['enabled']
    };
    const filters = mutableFilters[input];
    if (!filters) {
        return;
    }
    const mutatedFilters = filters.map(value => ({
        value,
        display: Config.headers[value],
        data: [],
    }));
    return mutatedFilters;

};

export const headerOrder = (input: any) => {
    if (input === undefined) {
        input = 'default';
    }
    const headerOrderObject = {
        /* eslint-disable */
        candidates: ['id', 'condition', 'ivrsCode', 'firstName', 'surname', 'dob', 'gender', 'created', 'dateApplied', 'status', 'contactNumber', 'notes'],
        candidates_edit: ['id', 'ivrsCode', 'firstName', 'surname', 'dob', 'gender', 'email', 'phone', 'status', 'notes', 'websiteResponses', 'screenResponses', 'studyId', 'studySiteId', 'utmData', 'created', 'siteVisitDate'],
        clients: ['name', 'contactName', 'contactEmail'],
        clients_edit: ['name', 'contactName', 'contactEmail'],
        extract: ['id', 'firstName', 'surname', 'gender', 'dob', 'email', 'contactNumber', 'country', 'postcode'],
        investigators: ['id', 'firstName', 'surname', 'email', 'contactNumber', 'studySiteId'],
        investigators_edit: ['id', 'firstName', 'surname', 'email', 'contactNumber', 'studySiteId'],
        studies: ['id', 'status'],
        default: ['id', 'ivrsCode', 'firstName', 'surname', 'dob', 'gender', 'created', 'dateApplied', 'status', 'contactNumber', 'notes'],
        /* eslint-enable */
    };
    return headerOrderObject[input];
};

export const sortObject = (obj, page) => {
    if (!obj) {
        return undefined;
    }
    const sorting = headerOrder(page).map(h => h);
    const newSort = Array<any>();
    const keys = Object.keys(obj);
    const sorted = keys.map((item) => {
        const n = sorting.indexOf(item);
        sorting[n] = '';
        const x = [n, item];
        return x;
    })
        .sort((a, b) => a[0] - b[0])
        .map((j) => { newSort.push(j); return (j[0] !== -1) ? j[1] : ''; })
        .filter(value => Object.keys(value).length !== 0);
    const result = {};
    sorted.forEach((item) => {
        result[item] = obj[item];
    });
    return result;
};

export const sortHeaders = (items, page) => {
    if (!items) {
        return undefined;
    }
    const sorting = headerOrder(page).map(h => h);
    const newSort = Array<any>();
    const sorted = items.map((item) => {
        const n = sorting.indexOf(item.slug);
        sorting[n] = '';
        return [n, item];
    })
        .sort((a, b) => a[0] - b[0])
        .map((j) => { newSort.push(j); return (j[0] !== -1) ? j[1] : ''; })
        .filter(value => Object.keys(value).length !== 0);
    return sorted;
};

export const organiseFilters = (data) => {
    const allKeys = Array<any>();
    data.forEach((datum) => {
        Object.keys(datum).forEach((key) => {
            allKeys.push(key);
        });
    });
    const uniqueKeys = [...new Set(allKeys.map(item => item))];

    const uniqueData = {};
    uniqueKeys.forEach((uniqueKey) => {
        uniqueData[uniqueKey] = [];
    });

    uniqueKeys.forEach((key) => {
        uniqueData[key] = [...new Set(data.map((item) => {
            const curr = item[key];
            if (curr) {
                return curr.display;
            }
        }))].sort();
    });
    return uniqueData;
};

export const sanitiseLegend = (textArray, colorArray) => {
    const items = Array<any>();
    textArray.forEach((value, index) => {
        items.push({
            text: value,
            color: colorArray[index],
        });
    });
    return items;
};

export const sanitiseUserExtract = (input) => {
    let headers = Array<any>();
    Object.keys(input).forEach((key) => {
        headers.push({
            slug: key,
            display: input[key].display,
        });
    });
    headers = sortHeaders(headers, 'extract').map(header => header.display);
    Object.keys(input).forEach((key) => {
        if (!headers.includes(input[key].display)) {
            delete input[key];
        }
    });
    return input;
};

export const addLoading = (component, button) => new Promise((resolve) => {
    Helper.setInitialDimensions(button);
    const loading = component.state.loading.slice();
    loading.push(button.id);
    component.setState({ loading });
    resolve(true);
});

export const removeLoading = (component, button) => new Promise((resolve) => {
    component.setState({ loading: component.state.loading.filter(e => e !== button.id) });
    resolve(true);
});

export const disableButton = button => new Promise((resolve) => {
    button.disabled = true;
    button.classList.add('button-disabled');
    resolve(true);
});

export const enableButton = button => new Promise((resolve) => {
    button.disabled = false;
    button.classList.remove('button-disabled');
    resolve(true);
});

export const getToggleValue = (ref: { state: { checked: boolean; }, props: any }) => {
    return (ref && ref.state) ?
        ref.state.checked : undefined;
};

export const getRefValue = (ref: { state: { value: any; }, props: any }) => {
    return (ref && ref.state && ref.state.value) ?
        ref.state.value :
        ((ref && ref.props) ? ref.props.selected : undefined)
        || undefined;
};

export const convertToTable = (data: any, accessGraph: any = null) => {
    const result = {
        rows: Array<any>(),
        headers: Array<any>(),
    };

    if (!data || (Object.entries(data).length === 0 && data.constructor === Object)) {
        return result;
    }

    convertDataFromObject(result, data, accessGraph);
    return result;
};

export const convertDataFromObject = (result: { headers: Array<any>, rows: Array<any> }, data: any, accessGraph: any = null) => {
    const currentData = data[0];
    if (!currentData) {
        return result;
    }
    Object.keys(currentData).forEach((key) => {
        if (excludeFromTable.indexOf(key) === -1) {
            const current = currentData[key];
            if (current.display) {
                result.headers.push({ display: current.display || key, slug: key });
            }
        }
    });
    data.forEach((datum: any) => {
        if (datum.client) {
            let clientValue = '';
            if (accessGraph) {
                _.each(accessGraph?.clients, client => {
                    if (client.clientId === datum.client.value || client.name === datum.client.value) {
                        clientValue = client.name;
                    }
                })
            }
            datum.client.value = clientValue;
        }
        result.rows.push(datum);
    });
    return result;
};

export const constructRolesSelect = (value: any) => {
    const { role, text } = value;
    return {
        key: role,
        value: role,
        display: text,
    };
};

export const constructForSelect = (value: any) => {
    const { _id, id, name, firstName, surname } = value;
    return {
        key: _id || id,
        value: _id || id,
        display: name || `${firstName} ${surname}`
    };
};

export const filterForSelect = (value: any) => {
    const { enabled } = value;
    return enabled === 'Active';
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export const getValue = (obj: { value: any; }) => {
    return obj ? obj.value : undefined;
};

export const isContainedInUrl = (path: string) => {
    return window.location.pathname.indexOf(path) > -1;
};

export const replaceAll = function (string, search, replacement) {
    return string.replace(new RegExp(search, 'g'), replacement);
};

export const selectOptionNoValue = 'NO__SELECT_VALUE';

export const selectOption = { key: 'select-option', value: selectOptionNoValue, display: 'Select an option' };

export const convertForDropDown = (item: any) => {
    return constructForSelect({
        id: getValue(item.id),
        name: item.name
            ? (getValue(item.enabled) === 'Active' ? getValue(item.name) : `(Deactivated) ${getValue(item.name)}`)
            : (`${getValue(item.firstName)} ${`${getValue(item.surname)} `}`),
        enabled: getValue(item.enabled)
    });
}

export const filterDisabledForDropDown = (item: any) => {
    return true;
}

export const convertToOptions = ({ content, defaultValueIfNoId= '' }) => {
    const options = content.map(item => {
        const itemProp = {
            id: { value: getValue(item.id) || item[defaultValueIfNoId] },
            name: { value: getValue(item.name) || item.name },
            enabled: { value: getValue(item.enabled) || item.enabled },
            isSelected: false
        }

        return convertForDropDown(itemProp)
    });

    options.unshift(selectOption);
    return options;
}

export const logMobX = (obj: any) => {
    console.log(JSON.parse(JSON.stringify(obj)));
}

const excludeFromTable = ['_id', '__v', 'modified', 'allocations'];
